import Icon from './img/mega-logo.png'
import Img from './img/img.jpg'
import './App.css'

const App = () => {
    return (
        <div>
            <div className="popup">
                <div className="container">
                    <div className="popup__top">
                        <a href="#" target="_blank" className="popup__logo">
                            <svg viewBox="0 0 24 24" aria-hidden="true"
                                 className="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-lrvibr r-m6rgpd r-meisx5 r-1nao33i r-16y2uox r-lwhw9o">
                                <g>
                                    <path
                                        d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
                                </g>
                            </svg>
                        </a>
                        <a href="#" target="_blank" className="popup__more">
                            <svg viewBox="0 0 24 24" aria-hidden="true"
                                 className="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-lrvibr r-m6rgpd r-1yevf0r r-meisx5"
                                 style={{color: "rgb(239, 243, 244)"}}>
                                <g>
                                    <path
                                        d="M3 12c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2zm9 2c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm7 0c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"></path>
                                </g>
                            </svg>
                        </a>
                    </div>
                    <div className="popup__bottom">
                        <div className="popup__left">
                            <div className="popup__title">Stay up to date</div>
                            <div className="popup__text">X users will know everything first.</div>
                        </div>
                        <div className="popup__right">
                            <a href="https://x.com" target="_blank" className="popup__btn one">Login</a>
                            <a href="https://x.com" target="_blank"
                               className="popup__btn two">Register</a>
                        </div>
                    </div>
                </div>
            </div>
            <main className="main">
                <div className="container">
                    <div className="main__left">
                        <a href="https://x.com" target="_blank" className="main__logo">
                            <svg viewBox="0 0 24 24" aria-hidden="true"
                                 className="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-lrvibr r-m6rgpd r-lrsllp r-1nao33i r-16y2uox r-8kz0gk">
                                <g>
                                    <path
                                        d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
                                </g>
                            </svg>
                        </a>
                        <a href="https://x.com/settings" target="_blank" className="main__settings">
                            <svg viewBox="0 0 24 24" aria-hidden="true"
                                 className="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-lrvibr r-m6rgpd r-1nao33i r-lwhw9o r-cnnz9e">
                                <g>
                                    <path
                                        d="M10.54 1.75h2.92l1.57 2.36c.11.17.32.25.53.21l2.53-.59 2.17 2.17-.58 2.54c-.05.2.04.41.21.53l2.36 1.57v2.92l-2.36 1.57c-.17.12-.26.33-.21.53l.58 2.54-2.17 2.17-2.53-.59c-.21-.04-.42.04-.53.21l-1.57 2.36h-2.92l-1.58-2.36c-.11-.17-.32-.25-.52-.21l-2.54.59-2.17-2.17.58-2.54c.05-.2-.03-.41-.21-.53l-2.35-1.57v-2.92L4.1 8.97c.18-.12.26-.33.21-.53L3.73 5.9 5.9 3.73l2.54.59c.2.04.41-.04.52-.21l1.58-2.36zm1.07 2l-.98 1.47C10.05 6.08 9 6.5 7.99 6.27l-1.46-.34-.6.6.33 1.46c.24 1.01-.18 2.07-1.05 2.64l-1.46.98v.78l1.46.98c.87.57 1.29 1.63 1.05 2.64l-.33 1.46.6.6 1.46-.34c1.01-.23 2.06.19 2.64 1.05l.98 1.47h.78l.97-1.47c.58-.86 1.63-1.28 2.65-1.05l1.45.34.61-.6-.34-1.46c-.23-1.01.18-2.07 1.05-2.64l1.47-.98v-.78l-1.47-.98c-.87-.57-1.28-1.63-1.05-2.64l.34-1.46-.61-.6-1.45.34c-1.02.23-2.07-.19-2.65-1.05l-.97-1.47h-.78zM12 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5c.82 0 1.5-.67 1.5-1.5s-.68-1.5-1.5-1.5zM8.5 12c0-1.93 1.56-3.5 3.5-3.5 1.93 0 3.5 1.57 3.5 3.5s-1.57 3.5-3.5 3.5c-1.94 0-3.5-1.57-3.5-3.5z"></path>
                                </g>
                            </svg>
                            <span>Settings</span>
                        </a>
                    </div>
                    <div className="main__center">
                        <a href="https://x.com" target="_blank" className="back">
                            <svg viewBox="0 0 24 24" aria-hidden="true"
                                 className="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-lrvibr r-m6rgpd r-z80fyv r-19wmn03"
                                 style={{color: "rgb(239, 243, 244)"}}>
                                <g>
                                    <path
                                        d="M7.414 13l5.043 5.04-1.414 1.42L3.586 12l7.457-7.46 1.414 1.42L7.414 11H21v2H7.414z"></path>
                                </g>
                            </svg>
                            <span>Publish a post</span>
                        </a>
                        <div className="top">
                            <a href="https://x.com/" target="_blank" className="logo">
                                <img src={Icon} alt="img" className="logo__icon"/>
                                <div className="logo__box">
                                    <div className="logo__title">
                                        <span>MegaETH</span>
                                        <svg viewBox="0 0 22 22" aria-label="Подлинная учетная запись" role="img"
                                             className="r-4qtqp9 r-yyyyoo r-1xvli5t r-bnwqim r-lrvibr r-m6rgpd r-ea8lvw r-1o7j8w5 r-3t4u6i"
                                             data-testid="icon-verified" style={{width: "17px"}}>
                                            <g>
                                                <linearGradient gradientUnits="userSpaceOnUse" id="0-a" x1="4.411"
                                                                x2="18.083" y1="2.495" y2="21.508">
                                                    <stop offset="0" stopColor="#f4e72a"></stop>
                                                    <stop offset=".539" stopColor="#cd8105"></stop>
                                                    <stop offset=".68" stopColor="#cb7b00"></stop>
                                                    <stop offset="1" stopColor="#f4ec26"></stop>
                                                    <stop offset="1" stopColor="#f4e72a"></stop>
                                                </linearGradient>
                                                <linearGradient gradientUnits="userSpaceOnUse" id="0-b" x1="5.355"
                                                                x2="16.361" y1="3.395" y2="19.133">
                                                    <stop offset="0" stopColor="#f9e87f"></stop>
                                                    <stop offset=".406" stopColor="#e2b719"></stop>
                                                    <stop offset=".989" stopColor="#e2b719"></stop>
                                                </linearGradient>
                                                <g clipRule="evenodd" fillRule="evenodd">
                                                    <path
                                                        d="M13.324 3.848L11 1.6 8.676 3.848l-3.201-.453-.559 3.184L2.06 8.095 3.48 11l-1.42 2.904 2.856 1.516.559 3.184 3.201-.452L11 20.4l2.324-2.248 3.201.452.559-3.184 2.856-1.516L18.52 11l1.42-2.905-2.856-1.516-.559-3.184zm-7.09 7.575l3.428 3.428 5.683-6.206-1.347-1.247-4.4 4.795-2.072-2.072z"
                                                        fill="url(#0-a)"></path>
                                                    <path
                                                        d="M13.101 4.533L11 2.5 8.899 4.533l-2.895-.41-.505 2.88-2.583 1.37L4.2 11l-1.284 2.627 2.583 1.37.505 2.88 2.895-.41L11 19.5l2.101-2.033 2.895.41.505-2.88 2.583-1.37L17.8 11l1.284-2.627-2.583-1.37-.505-2.88zm-6.868 6.89l3.429 3.428 5.683-6.206-1.347-1.247-4.4 4.795-2.072-2.072z"
                                                        fill="url(#0-b)"></path>
                                                    <path
                                                        d="M6.233 11.423l3.429 3.428 5.65-6.17.038-.033-.005 1.398-5.683 6.206-3.429-3.429-.003-1.405.005.003z"
                                                        fill="#d18800"></path>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="logo__text">@megaeth_labs</div>
                                </div>
                            </a>
                            <div className="top__right">
                                <a href="https://x.com" target="_blank" className="top__read">Read</a>
                                <a href="https://x.com" target="_blank" className="top__btn">
                                    <svg viewBox="0 0 24 24" aria-hidden="true"
                                         className="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-lrvibr r-m6rgpd r-1xvli5t r-1hdv0qi">
                                        <g>
                                            <path
                                                d="M3 12c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2zm9 2c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm7 0c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"></path>
                                        </g>
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div className="text">
                            <p>
                                MegaETH is coming into the game! Official start of the
                                <a href="https://app.uniswap.org/swap?outputCurrency=0x8BA0a028038fc8A2B4A457Ef4bAC82c157dad83f&chain=ethereum"> #MegaETH</a>
                                &nbsp; Pre-market!
                            </p>
                            <p>
                                Buy : <a
                                href="https://app.uniswap.org/swap?outputCurrency=0x8BA0a028038fc8A2B4A457Ef4bAC82c157dad83f&chain=ethereum"
                                style={{wordBreak: "break-all"}}>https://app.uniswap.org/swap?outputCurrency=0x8BA0a028038fc8A2B4A457Ef4bAC82c157dad83f&chain=ethereum</a>
                            </p>
                            <p>
                                Powered by 🦄 Uniswap.
                            </p>
                        </div>
                        <a href="https://x.com" target="_blank" className="text__translate">Translate post</a>
                        <img src={Img} alt="img" className="image"/>
                        <div className="date">
                            <a href="https://x.com" target="_blank">1:30 AM · Oct 7, 2024 ·</a> <b>21,022</b>
                            <span>views</span>
                        </div>
                        <div className="links">
                            <div className="links__item">
                                <b>11</b>
                                <a href="https://x.com" target="_blank">Reposts</a>
                            </div>
                            <div className="links__item">
                                <b>71</b>
                                <a href="https://x.com" target="_blank">Like mark</a>
                            </div>
                            <div className="links__item">
                                <b>2</b>
                                <span>bookmarks</span>
                            </div>
                        </div>
                        <div className="buttons">
                            <a href="https://x.com" target="_blank" className="button">
                                <svg viewBox="0 0 24 24" aria-hidden="true"
                                     className="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-lrvibr r-m6rgpd r-50lct3 r-1srniue">
                                    <g>
                                        <path
                                            d="M1.751 10c0-4.42 3.584-8 8.005-8h4.366c4.49 0 8.129 3.64 8.129 8.13 0 2.96-1.607 5.68-4.196 7.11l-8.054 4.46v-3.69h-.067c-4.49.1-8.183-3.51-8.183-8.01zm8.005-6c-3.317 0-6.005 2.69-6.005 6 0 3.37 2.77 6.08 6.138 6.01l.351-.01h1.761v2.3l5.087-2.81c1.951-1.08 3.163-3.13 3.163-5.36 0-3.39-2.744-6.13-6.129-6.13H9.756z"></path>
                                    </g>
                                </svg>
                            </a>
                            <a href="https://x.com" target="_blank" className="button">
                                <svg viewBox="0 0 24 24" aria-hidden="true"
                                     className="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-lrvibr r-m6rgpd r-50lct3 r-1srniue">
                                    <g>
                                        <path
                                            d="M4.5 3.88l4.432 4.14-1.364 1.46L5.5 7.55V16c0 1.1.896 2 2 2H13v2H7.5c-2.209 0-4-1.79-4-4V7.55L1.432 9.48.068 8.02 4.5 3.88zM16.5 6H11V4h5.5c2.209 0 4 1.79 4 4v8.45l2.068-1.93 1.364 1.46-4.432 4.14-4.432-4.14 1.364-1.46 2.068 1.93V8c0-1.1-.896-2-2-2z"></path>
                                    </g>
                                </svg>
                            </a>
                            <a href="https://x.com" target="_blank" className="button">
                                <svg viewBox="0 0 24 24" aria-hidden="true"
                                     className="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-lrvibr r-m6rgpd r-50lct3 r-1srniue">
                                    <g>
                                        <path
                                            d="M16.697 5.5c-1.222-.06-2.679.51-3.89 2.16l-.805 1.09-.806-1.09C9.984 6.01 8.526 5.44 7.304 5.5c-1.243.07-2.349.78-2.91 1.91-.552 1.12-.633 2.78.479 4.82 1.074 1.97 3.257 4.27 7.129 6.61 3.87-2.34 6.052-4.64 7.126-6.61 1.111-2.04 1.03-3.7.477-4.82-.561-1.13-1.666-1.84-2.908-1.91zm4.187 7.69c-1.351 2.48-4.001 5.12-8.379 7.67l-.503.3-.504-.3c-4.379-2.55-7.029-5.19-8.382-7.67-1.36-2.5-1.41-4.86-.514-6.67.887-1.79 2.647-2.91 4.601-3.01 1.651-.09 3.368.56 4.798 2.01 1.429-1.45 3.146-2.1 4.796-2.01 1.954.1 3.714 1.22 4.601 3.01.896 1.81.846 4.17-.514 6.67z"></path>
                                    </g>
                                </svg>
                            </a>
                            <a href="https://x.com" target="_blank" className="button">
                                <svg viewBox="0 0 24 24" aria-hidden="true"
                                     className="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-lrvibr r-m6rgpd r-50lct3 r-1srniue">
                                    <g>
                                        <path
                                            d="M4 4.5C4 3.12 5.119 2 6.5 2h11C18.881 2 20 3.12 20 4.5v18.44l-8-5.71-8 5.71V4.5zM6.5 4c-.276 0-.5.22-.5.5v14.56l6-4.29 6 4.29V4.5c0-.28-.224-.5-.5-.5h-11z"></path>
                                    </g>
                                </svg>
                                <span>2</span>
                            </a>
                            <a href="https://x.com" target="_blank" className="button">
                                <svg viewBox="0 0 24 24" aria-hidden="true"
                                     className="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-lrvibr r-m6rgpd r-50lct3 r-1srniue">
                                    <g>
                                        <path
                                            d="M12 2.59l5.7 5.7-1.41 1.42L13 6.41V16h-2V6.41l-3.3 3.3-1.41-1.42L12 2.59zM21 15l-.02 3.51c0 1.38-1.12 2.49-2.5 2.49H5.5C4.11 21 3 19.88 3 18.5V15h2v3.5c0 .28.22.5.5.5h12.98c.28 0 .5-.22.5-.5L19 15h2z"></path>
                                    </g>
                                </svg>
                            </a>
                        </div>
                        <a href="https://x.com" target="_blank" className="sort">
                            <div className="sort__left">
                                <svg viewBox="0 0 24 24" aria-hidden="true"
                                     className="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-lrvibr r-m6rgpd r-1nao33i r-1q142lx r-v24l6w r-1wron08 r-qu3fyt">
                                    <g>
                                        <path
                                            d="M14 6V3h2v8h-2V8H3V6h11zm7 2h-3.5V6H21v2zM8 16v-3h2v8H8v-3H3v-2h5zm13 2h-9.5v-2H21v2z"></path>
                                    </g>
                                </svg>
                                <div className="sort__text">The most relevant</div>
                            </div>
                            <svg viewBox="0 0 24 24" aria-hidden="true" className="sort__arrow">
                                <g>
                                    <path
                                        d="M3.543 8.96l1.414-1.42L12 14.59l7.043-7.05 1.414 1.42L12 17.41 3.543 8.96z"></path>
                                </g>
                            </svg>
                        </a>
                        <div className="line"></div>
                    </div>
                    <div className="main__right">
                        <div className="right__box">
                            <div className="right__title">New to X?</div>
                            <div className="right__text">Sign up now to personalize your feed!</div>
                            <a href="https://x.com" target="_blank" className="right__btn">
                                <svg viewBox="0 0 24 24" aria-hidden="true"
                                     className="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-lrvibr r-m6rgpd r-1gs4q39 r-z80fyv r-19wmn03"
                                     style={{color: "rgb(15, 20, 25)"}}>
                                    <g>
                                        <path
                                            d="M16.365 1.43c0 1.14-.493 2.27-1.177 3.08-.744.9-1.99 1.57-2.987 1.57-.12 0-.23-.02-.3-.03-.01-.06-.04-.22-.04-.39 0-1.15.572-2.27 1.206-2.98.804-.94 2.142-1.64 3.248-1.68.03.13.05.28.05.43zm4.565 15.71c-.03.07-.463 1.58-1.518 3.12-.945 1.34-1.94 2.71-3.43 2.71-1.517 0-1.9-.88-3.63-.88-1.698 0-2.302.91-3.67.91-1.377 0-2.332-1.26-3.428-2.8-1.287-1.82-2.323-4.63-2.323-7.28 0-4.28 2.797-6.55 5.552-6.55 1.448 0 2.675.95 3.6.95.865 0 2.222-1.01 3.902-1.01.613 0 2.886.06 4.374 2.19-.13.09-2.383 1.37-2.383 4.19 0 3.26 2.854 4.42 2.955 4.45z"></path>
                                    </g>
                                </svg>
                                <span>Register with Apple ID</span>
                            </a>
                            <a href="https://x.com/i/flow/signup" target="_blank" className="right__btn">
                                <span>Register</span>
                            </a>
                            <div className="right__hint">
                                By registering, you agree to the <a href="https://x.com/tos" target="_blank">Terms of
                                Service</a> and <a href="https://x.com/privacy" target="_blank">Privacy Policy</a> , as
                                well as the <a href="https://help.x.com/rules-and-policies/twitter-cookies"
                                               target="_blank">Cookie Policy</a>.
                            </div>
                        </div>
                        <div className="right__links">
                            <a href="https://x.com/tos" target="_blank">Terms of Service</a> <a
                            href="https://x.com/privacy" target="_blank">Privacy Policy</a> <a
                            href="https://support.x.com/articles/20170514" target="_blank">Cookie Policy</a> <a
                            href="https://help.x.com/resources/accessibility" target="_blank">Special Features</a> <a
                            href="https://business.x.com/en/help/troubleshooting/how-twitter-ads-work.html?ref=web-twc-ao-gbl-adsinfo&utm_source=twc&utm_medium=web&utm_campaign=ao&utm_content=adsinfo"
                            target="_blank">Advertising information</a> <a href="https://x.com"
                                                                           target="_blank">More</a> © 2024 X Corp.
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default App;